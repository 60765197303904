<template>
  <v-btn class="pa-7 rounded-lg" color="" outlined  @click="AuthProvider('github')"> 
    <v-icon class="mr-2">mdi-github</v-icon>
    Sign in with Github
  </v-btn>
</template>

<script>
import colorMxn from '@/mixins/color_mixin';
import { USER_FIELDS } from '@/queries/account';
import gql from 'graphql-tag';

export default {
  name:"Github",
  mixins: [colorMxn],
  mounted() {
  },
  methods: {
    AuthProvider(provider) {
      var self = this
      this.$auth.authenticate(provider).then(response =>{
        self.post(response);
      }).catch(err => {
        console.log({err:err})
    })},
    post(response) {
      
      let payload = {
        socialAuthAccessToken: response.code,
        socialAuthProvider: 'github',
      };

      const POST_AUTH= gql`mutation($socialAuthAccessToken: String!, $socialAuthProvider: String!) {
        socialAuth(accessToken: $socialAuthAccessToken, provider: $socialAuthProvider) {
          social {
            provider
            uid
            extraData
            created
            modified
            user {
              ${USER_FIELDS}
            },
          }
          token
          refreshToken
        }
      }`;
      
      this.$apollo.mutate({
        mutation: POST_AUTH,
        variables: payload,
        update: (cache, {data, errors}) => {
          this.$store.state.login_success = true;
          let user = data.socialAuth.social.user;
          this.$store.state.user = user
          let dt = {
            "token": data.socialAuth.token,
            "refresh_token": data.socialAuth.refreshToken,
            "push": true,
          };
          this.$store.commit("completeLogin", dt)
        },
      }) 
    
    }

  }
};
</script>
